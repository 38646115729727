import React from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import cert1 from '../../img/cert1.png';
import cert2 from '../../img/cert2.png';
import Layout from '../../components/Layout'
import left from '../../img/arrow-left-white.svg'
import right from '../../img/arrow-right-white.svg'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGallery: false,
      currentGalleryIndex: 0,
      isValidated: false 
    }

    this.certs = new Array(cert1, cert2);
  }

  showGallery()
  {
    this.setState({
      showGallery: true,
    });
  }

  hideGallery()
  {
    this.setState({
      showGallery: false,
    });
  }

  rightButtonClicked()
{
  let index = this.state.currentGalleryIndex >= this.certs.length - 1 ? 0 : this.state.currentGalleryIndex + 1;

  this.setState({
    currentGalleryIndex: index
  });
}

leftButtonClicked()
{
  let index = this.state.currentGalleryIndex <= 0 ? this.certs.length - 1 : this.state.currentGalleryIndex - 1;
  
  this.setState({
    currentGalleryIndex: index
  });
}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    let label = e.target.nextSibling;
    if (e.target.value == '')
    {
        label.classList.remove("hidden")
    }
    else
    {
        label.classList.add("hidden")
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section" style={{marginBottom: '100px'}}>
            <div className="container contact-page-container">
                <div className="columns">
                  <div className="column is-1"></div>
                    <div className="column is-10 column-big-responsive">
                        <div className="content has-text-centered" style={{marginTop: '4rem', marginBottom: '4rem'}}>
                            <h3 className="has-text-weight-semibold is-size-3 has-text-centered" style={{marginTop: '50px'}}>
                                Bezpłatna konsultacja
                            </h3>
                            <p className="has-text-justified">
                              Na pierwszym spotkaniu, które jest bezpłatne i do niczego nie zobowiązuje - poznamy się, opowiesz mi o swoich potrzebach, a ja o tym na czym polega praca coachingowa. Czas trwania około <span className="text-pink">30 minut</span>.
                            </p>
                            <h3 className="has-text-weight-semibold is-size-3 has-text-centered" style={{marginTop: '50px'}}>
                                Proces coachingowy
                            </h3>
                            <p className="has-text-justified">
                            Proces coachingowy ze mną pomoże Ci się rozwinąć, wydobyć Twoje mocne strony oraz pozbyć się
obaw i wątpliwości towarzyszących Ci w życiu codziennym. Pomoże dostrzec nowe rozwiązania,
perspektywy i możliwości potrzebne do tego, aby "ruszyć z miejsca". Da Ci nowe spojrzenie na Twoje
życie.
                            </p>
                            <p className="has-text-justified">
                            Proces coachingowy obejmuje kilka sesji, zazwyczaj od 5 do 10, jednakże nie jest to regułą i wszystko zależy od Twoich potrzeb. Wszystkie szczegóły spotkań ustalimy razem.
Jedna sesja trwa godzinę. Cena do uzgodnienia, w pakietach - taniej.
                              </p>
                              <p className="has-text-justified">
                              Jestem <span className="text-pink" style={{cursor: "pointer"}} onClick={() => this.showGallery()}><b>certyfikowanym</b></span>  life coachem.
                                </p>
                                <p className="has-text-justified">
                                Sesje prowadzę online.
                                  </p>
                                  <p className="has-text-justified">
                                  Jeżeli jesteś osobą zapracowaną, która lubi robić rzeczy w czasie dogodnym dla siebie, istnieje również
możliwość prowadzenia coachingu poprzez e-mail. Także w sytuacjach, gdy chciałbyś/chciałabyś zadać
pytanie w jednej konkretnej sprawie - możesz zamówić coaching meilowy.
                                    </p>
                            <Link className="btn" to="/kontakt" style={{ marginTop: '50px'}}>
                                Umów się na bezpłatną konsultację  
                            </Link>
                        </div>
                    </div>
                    <div className="column is-1"></div>
                </div>
            </div>
            { this.state.showGallery ? 
    <div className="cert-gallery">
      <div className="cert-gallery-background" onClick={() => this.hideGallery()}> </div>
     <img src={this.certs[this.state.currentGalleryIndex]} onClick={() => this.hideGallery()}></img>
     <div className="gallery-arrow gallery-arrow-left" onClick={() => this.leftButtonClicked()}><img src={left} /></div>
     <div className="gallery-arrow gallery-arrow-right" onClick={() => this.rightButtonClicked()} ><img src={right} /></div>
    </div>
      : null }
        </section>
      </Layout>
    )
  }
}